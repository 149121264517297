<script>
export default {
	metaInfo: {
		title: 'Nuestros Servicios',
	},
	pageView: true,
	mounted() {
		this.$prerenderReady()
	},
}
</script>
<template>
	<div>
		<Services-A-Header />
		<Services-B-Info class="py-15 my-15" />
	</div>
</template>
