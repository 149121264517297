<script>
export default {
	data() {
		return { items: this.$srv('Services_Info', []) }
	},
	computed: {
		isMobile() {
			return this.$vuetify.breakpoint.xs
		},
	},
}
</script>
<template>
	<div class="coral" v-if="items.length">
		<Container>
			<v-row v-for="(item, index) of items" :key="index" class="px-md-15 mb-15">
				<v-col
					cols="12"
					sm="8"
					:order="!item.inverted && !isMobile ? 1 : 3"
					class="px-10 d-flex flex-column align-center align-md-start"
				>
					<div
						class="h5 font-weight-bold text-uppercase"
						:class="isMobile && 'text-center'"
						:style="isMobile && 'font-size: 20px;'"
					>
						{{ item.title }}
					</div>
					<div class="t1 font-weight-bold mt-5 preline" :class="isMobile && 't3 text-center'">
						{{ item.text }}
					</div>
					<SafeLink to="contacto">
						<WButton x-large class="mt-5">Contactar</WButton>
					</SafeLink>
				</v-col>
				<v-col cols="12" sm="4" order="2" class="px-10">
					<Media :src="item.img" contain></Media>
				</v-col>
			</v-row>
		</Container>
	</div>
</template>
<style scoped>
.line {
	max-width: 10rem;
	height: 1px;
	background: black;
}
</style>
